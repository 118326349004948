<template>
    <router-view/>
</template>
<script lang="ts">
import importComponents from '@/utils/import-components';
import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'SupportIndex',
  components: {
    ...importComponents(
    ),
  },
  setup() {
    return {
    };
  },
});
</script>
